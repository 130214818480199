











































































































































































































































import { Component, Vue } from "vue-property-decorator";
import AlwaysAvailableQuestionnaireSubmissionForm from "@/components/forms/AlwaysAvailableQuestionnaireSubmissionForm.vue";

@Component({
  components: {
    AlwaysAvailableQuestionnaireSubmissionForm
  }
})
export default class Home extends Vue {
  private questionnaires: any = [];

  private showQuestionnaireDialog = false;
  private selectedQuestionnaire: any = null;
  private previousSubmission: any = null;

  private async showQuestionnaire(q: any) {
    const quest = await this.$service.providers.questionnaires.fetchItemAsync(
      q.id
    );
    this.selectedQuestionnaire = quest;
    this.previousSubmission = null;
    this.showQuestionnaireDialog = true;
  }

  private reset() {
    this.showQuestionnaireDialog = false;
    this.selectedQuestionnaire = null;
    this.previousSubmission = null;
  }

  async mounted() {
    this.questionnaires = (
      await this.$service.providers.questionnaires.fetchItemsAsync({
        columnFilters: [
          {
            column: "QuestionnaireType",
            value: `'AlwaysAvailable'`,
            op: "Equals"
          }
        ]
      })
    ).items;
  }
}
